<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewProgrammeSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-new-programme-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Create Service Clinic
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel()"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="CreatePcnForm"
      >
        <!-- Form -->
        <b-form
            @submit.prevent>
          <div
              class="p-2"
          >
            <!-- Full Name -->
            <b-form-group
                label="Service Clinic Name"
                label-for="full-name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Service Clinic Name"
                  rules="required|pcn_name_length"
              >

                <b-form-input
                    id="full-name"
                    v-model="programme.name"
                    autofocus
                    placeholder="e.g. Vaccination Service Clinic"
                    trim
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Dates -->
            <div class="row">
              <div class="col-6">

                <b-form-group
                    label="Start Date (optional)"
                    label-for="start_date"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                      rules=""
                  >

                    <b-form-datepicker
                        id="start_date"
                        :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }"
                        v-model="programme.start_date"
                        locale='en-UK'
                        class=""
                        :min="min"
                        :start-weekday="weekday"
                        placeholder="Select"
                        trim
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-6">

                <b-form-group
                    label="End Date (optional)"
                    label-for="end_date"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="End Date"
                      rules="">

                    <b-form-datepicker
                        id="end_date"
                        :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }"
                        v-model="programme.end_date"
                        :disabled="disableEndDate"
                        :start-weekday="weekday"
                        locale='en-UK'
                        class=""
                        placeholder="Select"
                        :min="minDate"
                        trim/>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6 demo-vertical-spacing">

                <validation-provider
                    #default="{ errors }"
                    name="Budget"
                    rules=""
                >
                  <b-form-group
                      label="Budget (optional)"
                      label-for="budget"
                  >
                    <b-input-group
                        class="input-group-merge"
                        prepend="£"
                        trim
                    >
                      <b-form-input id="budget"   v-model="programme.budget" type="number" placeholder=""/>
                    </b-input-group>

                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>


            <div class="divider mt-0 mb-2">
              <div class="divider-text p-0">

              </div>
            </div>

            <h5>Add Locations</h5>
            <label class="font-small-3 font-weight-bold pb-1">Select the locations participating in the service clinic from the
              list.</label>
            <!-- Todo List -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="todo-task-list-wrapper list-group scroll-area"
                style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"
            >
              <!-- <ul class="list-group">
                <li
                    v-for="task in tasks"
                    :key="task.id"
                    :class="{ 'completed': task.isCompleted }"
                    class="todo-item  list-group-item py-1"
                >
                  <b-form-checkbox
                      :checked="task.isCompleted"
                      @click.native.stop
                  >
                    <span class="todo-title">{{ task.title }}</span>
                  </b-form-checkbox>
                </li>

              </ul> -->
              <b-overlay
                  :show="tableLoading"
                  rounded="sm"
              >
                <b-table
                    id="table"
                    ref="table"
                    :current-page="currentPage"
                    :per-page="pagination.perPage"
                    :items="getLocationList"
                    :selectOnCheckboxOnly="true"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    empty-text="Please create location"
                    :fields="fields"
                    primary-key="id"
                    class="custom_align mobile_table_css bank-tbl"
                    hover
                    responsive
                >

                  <template #cell(selected)="data">
                    <b-form-checkbox class="p-0 m-0" name="check_box" @change="addThisToSelectedList(data.item)">

                    </b-form-checkbox>
                  </template>

                </b-table>
              </b-overlay>
            </vue-perfect-scrollbar>
            <div
                class="m-1"
            >
              <div class="mx-1 mb-2">
                <b-row>

                  <b-col
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      cols="12"
                      sm="6"
                  >
                    <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
                    <!-- <span class="text-muted">Showing 1 to 10 of 187 entries</span> -->
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      cols="12"
                      sm="6"
                  >

                    <b-pagination
                        v-model="currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        class="mb-0 mt-1 mt-sm-0 "
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </div>
            <b-alert
                :show="true"
                class="mb-0 mt-2 rounded"
                variant="info"
            >
              <div class="alert-body text-dark">
                <feather-icon
                    class="mr-50 text-success"
                    icon="InfoIcon"
                />
                You will be able to set clinical hours once you create a service clinic.
              </div>
            </b-alert>

            <!-- Form Actions -->
            <div class="d-flex mt-3">
              <b-button
                  id="save_btn"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  :disabled="programme.name == '' || hide_btnn || selected.length === 0 || hide_btn"
                  @click="createPrograme"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="shadow text-primary ml-1"
                  type="button"
                  variant="outline-white"
                  @click="cancel()"
              >
                Cancel
              </b-button>
            </div>

          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {tasks} from './todoTO_BE_REMOVED'
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTable,
  BPagination,
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import ProgrammesAPI from '@/apis/modules/programmes'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTable,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewProgrammeSidebarActive',
    event: 'update:is-add-new-programme-sidebar-active'
  },
  props: {
    isAddNewProgrammeSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data () {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minn = new Date(today)
    minn.setMonth(minn.getMonth())
    minn.setDate(minn.getDate())

    return {
      weekday: 1,
      filters:{},
      filter: null,
      filterOn: [],
      hide_btn:false,
      hide_btnn:false,
      currentPage: 1,
      minDate:'',
       min: minn,
      tableLoading: false,
      disableEndDate: true,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      selected: [],
      selectedCheck: true,
      selectedOption: {
        title: 'All',
        value: 1
      },
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'address',
          label: 'ADDRESS'
        },
        // {
        //   key: 'service_charge',
        //   label: 'SERVICE CHARGE'
        // },
      ],
      required,
      alphaNum,
      email,
      programme: {
        name: '',
        budget: '',
        start_date: '',
        end_date: '',
      },
      perfectScrollbarSettings: {

      },
      tasks: tasks
    }
  },
  methods: {
    addThisToSelectedList (item) {


      if (_.find(this.selected, {'id': item.id})) {

        _.remove(this.selected, function (e) {
          return e.id === item.id
        })
      } else {
        this.selected.push(item)
      }
      this.hide_btn = this.selected.length === 0
    },
    async getLocationList(){
      try{
        this.tableLoading = true
        const Response = await ProgrammesAPI.getLocations(this.currentPage, 10)

        //console.log(Response)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          service_charge: x.service_charge,
          address: x.address + ', '+ x.city + ', ' + x.postal_code,
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        var checkboxes = document.getElementsByName('check_box')
        for (var checkbox of checkboxes) {
          checkbox.checked = false
        }

        return dataArray;

      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

      this.tableLoading = false
    },
    async createPrograme(){

      // var saveButton = document.getElementsByName('save_btn')
      // saveButton.disabled = true
      //this.selected.length === 0

      this.$emit('dataRefresh')

      if (await this.$refs.CreatePcnForm.validate()){
        try{
          //this.tableLoading = true

          const data = this.selected.map((x) => (
              x.id
          ))

          let payload = {}
          payload.programme = this.programme;
          payload.location_ids = data

          await ProgrammesAPI.createProgramme(payload)

          this.programme.name = null
          this.programme.budget = null
          this.programme.start_date = null
          this.programme.end_date = null
          this.disableEndDate = true

          this.selected = []
          this.selected.length === 0

          this.$emit('dataRefresh')

          this.showSuccessMessage('New programme was created!')

          this.$refs.table.refresh()
          this.tableLoading = false

        }catch(error){
          this.convertAndNotifyError(error)
          this.selected = []
          this.selected.length === 0
          this.tableLoading = false
        }
      }

      this.$refs.table.refresh()
      this.tableLoading = false
    },
    cancel(){
      this.programme.name = null
      this.programme.budget = null
      this.programme.start_date = null
      this.programme.end_date = null
      this.disableEndDate = true

      this.selected = []
      this.selected.length === 0
      this.programme.name = null

      this.$emit('dataRefresh')
    },
    filterQueryUpdate () {
      //this.$refs.table.refresh()
    },
    dataRefresh(){
      this.$refs.table.refresh()
    },
    toggle () {
      //this.$emit('toggleSideBars')
      this.selected = []
      this.filters = {}
      this.$refs.table.refresh()
      this.isAddNewProgrammeSidebarActive = false
    },
  },
  watch: {
    isAddNewProgrammeSidebarActive (val){
      if(val) this.getLocationList()
      this.$refs.CreatePcnForm.reset();
    },

    'programme.start_date': function(value){
      this.programme.end_date = null
      this.minDate = new Date(value)
      if(value) this.disableEndDate = false
    }

  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

