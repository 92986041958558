<template>
  <div>
    <!--              table-->

    <b-row>
      <b-col class="">
         <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-table
            id="table"
            ref="table"
            :fields="fields"
            :items="getLocations"
            :current-page="currentPage"
            :per-page="pagination.perPage"
            @row-clicked="myRowClickHandler"
            hover
            class="position-relative mobile_table_css"
            empty-text="No matching records found"
            primary-key="id"
            responsive
            show-empty
          >
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>

    <!--                pagination-->
    <div class="mx-1 mb-2">
      <b-row>

        <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
        >
          <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
          <!-- <span class="text-muted">Showing 1 to 10 of 187 entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
        >

          <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>



  </div>
</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import ProgrammesAPI from '@/apis/modules/programmes'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mounted } from 'vue-echarts'

export default {
  name: 'locations',
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data: function () {
    return {
      currentPage: 1,
      tableLoading: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },

      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'address',
          sortable: false,
        },
        {
          key: 'city',
          sortable: false,
        },
        {
          key: 'postal_code',
          sortable: false,
          label: 'POSTAL CODE'
        },
        {
          key: 'telephone',
          sortable: false,
        },
        {
          key: 'email',
          sortable: false,
        },
        {
          key: 'service_charge',
          sortable: false,
          label: 'SERVICE CHARGE'
        },
      ],
      items: []
    }
  },
  methods: {

    async getLocations(){
       try{
        this.tableLoading = true

        const Response = await ProgrammesAPI.getProgrammeLocations(this.$route.params.id, this.currentPage, 15);

        //console.log(Response)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          address: x.address,
          city: x.city,
          postal_code: x.postal_code,
          telephone: x.telephone,
          email: x.email,
          service_charge: x.service_charge ? '£'+ x.service_charge : 'N/A',
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        return dataArray

      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    dataRefresh(){
      this.$refs.table.refresh()
    },
    myRowClickHandler(item) {
      this.$router.push(`/locations/${item.id}`)
    },
  },
}
</script>

<style scoped>
</style>
