<template>
  <div>
    <div class="my-2">
      Set your network's weekly target hours below.
    </div>

    <div class="form-group row">
      <div class="col-md-2 col-lg-1 mr-1">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Monday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_monday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Tuesday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_tuesday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Wednesday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_wednesday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Thursday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_thursday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Friday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_friday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Saturday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_saturday">
      </div>
      <div class="col-md-2 col-lg-1 mx-md-1 mx-lg-0 mt-1 mt-md-0">
        <label for="ex1"><span class="font-weight-bold font-medium-1">Sunday</span></label>
        <input class="form-control" id="ex1" type="number" value="10" v-model="hours_of_sunday">
      </div>
    </div>

    <div class="d-flex flex-row font-weight-bolder font-small-4 mt-3 p-1" style="background-color: #ebf5fb">
        <div class="mr-2">
          Target Weekly Hours: {{total_weekly_hours}}
        </div>
      <div>
        Target Monthly Hours: 272
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 font-weight-bolder">
      </div>
      <div class="col-md-3 font-weight-bold">
      </div>
    </div>
  </div>
</template>

<script>
import AddProgrammeSideBar from '@/views/programmes/sidebars/AddProgrammeSideBar'
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'clinical-hours',
  components: {
    AddProgrammeSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  data: function () {
    return {
      currentPage: 1,
      isAddNewProgrammeSidebarActive: false,

      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'role',
          sortable: true,
        },
        {
          key: 'number',
          sortable: true,
        },
        {
          key: 'telephone',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'total_hr_worked',
          sortable: true,
        },
        {
          key: 'total_earned',
          sortable: true,
        },
      ],
      items: [
        {
          "name": "Sandoval Kim",
          "role": "QUIZKA",
          "number": "15",
          "telephone": "+1 (876) 511-2396",
          "email": "sandovalkim@quizka.com",
          "total_hr_worked": "$3,428.56",
          "total_earned": "$3,428.56"
        },
        {
          "name": "Mildred Mcleod",
          "role": "ANDRYX",
          "number": "15",
          "telephone": "+1 (894) 516-2935",
          "email": "mildredmcleod@andryx.com",
          "total_hr_worked": "$3,428.56",
          "total_earned": "$3,428.56"
        },
        {
          "name": "Scott Garner",
          "role": "INTRADISK",
          "number": "15",
          "telephone": "+1 (820) 556-2808",
          "email": "scottgarner@intradisk.com",
          "total_hr_worked": "$3,428.56",
          "total_earned": "$3,428.56"
        },
        {
          "name": "Minerva Herman",
          "role": "POLARIUM",
          "number": "15",
          "telephone": "+1 (995) 486-2958",
          "email": "minervaherman@polarium.com",
          "total_hr_worked": "$3,428.56",
          "total_earned": "$3,428.56"
        },
        {
          "name": "Faith Hutchinson",
          "role": "INVENTURE",
          "number": "15",
          "telephone": "+1 (849) 586-2381",
          "email": "faithhutchinson@inventure.com",
          "total_hr_worked": "$3,428.56",
          "total_earned": "$3,428.56"
        }
      ],
      hours_of_monday : 10,
      hours_of_tuesday : 10,
      hours_of_wednesday : 10,
      hours_of_thursday : 10,
      hours_of_friday : 10,
      hours_of_saturday : 10,
      hours_of_sunday : 10,
      total_weekly_hours : ''
    }
  },

  watch: {
    hours_of_monday() {
      this.totalWeeklyHours()
    },
    hours_of_tuesday() {
      this.totalWeeklyHours()
    },
    hours_of_wednesday() {
      this.totalWeeklyHours()
    },
    hours_of_thursday() {
      this.totalWeeklyHours()
    },
    hours_of_friday() {
      this.totalWeeklyHours()
    },
    hours_of_saturday() {
      this.totalWeeklyHours()
    },
    hours_of_sunday() {
      this.totalWeeklyHours()
    },

  },

  computed : {
    totalWeeklyHours() {
      this.total_weekly_hours = parseInt(this.hours_of_monday) + parseInt(this.hours_of_tuesday) + parseInt(this.hours_of_wednesday) + parseInt(this.hours_of_thursday)
      + parseInt(this.hours_of_friday) + parseInt(this.hours_of_saturday) + parseInt(this.hours_of_sunday) ;
      return this.total_weekly_hours
    }
  },

  mounted () {
    this.totalWeeklyHours()
  }
}
</script>

<style scoped>

</style>
