import api from '../axios';

export default {
  getProgrammesList: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/pcn/programmes/?page=${page}&paginate=${paginate}&sort=${
        desc ? '' : '-'
      }${sort}&${filterQuery}`
    ),

  getProgrammeDetails: (id) => api.get(`api/pcn/programmes/${id}`),
  createProgramme: (payload) => api.post(`api/pcn/programmes`, payload),
  updateProgramme: (id) => api.put(`api/pcn/programmes/${id}`),
  getLocations: (page, paginate) =>
    api.get(`/api/pcn/locations/?page=${page}&paginate=${paginate}`),
  getProgrammeLocations: (id, page, paginate) =>
    api.get(
      `/api/pcn/programmes/${id}/locations?page=${page}&paginate=${paginate}`
    ),
  getProgrammeStaff: (id, page, paginate) =>
    api.get(
      `/api/pcn/programmes/${id}/staff?page=${page}&paginate=${paginate}`
    ),
  editProgramme: (id, payload) => api.put(`/api/pcn/programmes/${id}`, payload),
};
