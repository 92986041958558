<template>
  <b-sidebar
      id="edit-new-user-sidebar"
      :visible="isEditProgrammeSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-programme-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Service Clinic
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel()"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="UpdatePcnForm"
      >
        <!-- Form -->
        <b-form
            @submit.prevent>
          <div
              class="p-2"
          >
            <!-- Full Name -->
            <b-form-group
                label="Service Clinic Name"
                label-for="full-name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Service Clinic Name"
                  rules="required|pcn_name_length"
              >

                <b-form-input
                    id="full-name"
                    v-model="programme.name"
                    autofocus
                    placeholder="e.g. Vaccination Service Clinic"
                    trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Dates -->
            <div class="row">
              <div class="col-6">

                <b-form-group
                    label="Start Date"
                    label-for="start_date"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                  >
                    <b-form-datepicker
                        id="start_date"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        v-model="programme.start_date"
                        class=""
                        placeholder="Select"
                        trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-6">

                <b-form-group
                    label="End Date"
                    label-for="end_date"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="End Date"
                  >
                    <b-form-datepicker
                        id="end_date"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        v-model="programme.end_date"
                        class=""
                        placeholder="Select"
                        trim
                        :min="minDate"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6 demo-vertical-spacing">

                <validation-provider
                    #default="{ errors }"
                    name="Budget"
                    rules=""
                >
                  <b-form-group
                      label="Budget (optional)"
                      label-for="budget"
                  >
                    <b-input-group
                        class="input-group-merge"
                        prepend="£"
                        trim
                    >
                      <b-form-input id="budget"   v-model="programme.budget" type="number" placeholder=""/>
                    </b-input-group>

                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>


            <div class="divider mt-0 mb-2">
              <div class="divider-text p-0">

              </div>
            </div>

            <h5>Add Locations</h5>
            <label class="font-small-3 font-weight-bold pb-1">Select the locations participating in the service clinic from the
              list.</label>
            <!-- Todo List -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="todo-task-list-wrapper list-group scroll-area"
                style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"
            >
              <b-overlay
                  :show="tableLoading"
                  rounded="sm"
              >
                <b-table
                    id="table"
                    ref="table"
                    :current-page="currentPage"
                    :per-page="pagination.perPage"
                    empty-text="Please create location"
                    :items="getLocationList"
                    :selectOnCheckboxOnly="true"
                    :fields="fields"
                    primary-key="id"
                    class="custom_align mobile_table_css bank-tbl"
                    hover
                    responsive
                >

                  <template #cell(selected)="data">
                    <b-form-checkbox
                        v-model="checkedLocation"
                        :value="data.item"
                        class="p-0 m-0"
                        name="check_box">
                    </b-form-checkbox>
                  </template>

                  <!-- <template #cell(selected)="data">
                     <label v-for="selected in alreadySelected" :key="selected.id" >
                       <b-form-checkbox class="p-0 m-0" :checked="data.item.id == selected.id" name="check_box" @change="addThisToSelectedList(data.item)">
                       </b-form-checkbox>
                     </label>
                 </template>  -->
                </b-table>
              </b-overlay>
            </vue-perfect-scrollbar>
            <div
                class="m-1"
            >
              <div class="mx-1 mb-2">
                <b-row>

                  <b-col
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      cols="12"
                      sm="6"
                  >
                    <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
                    <!-- <span class="text-muted">Showing 1 to 10 of 187 entries</span> -->
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      cols="12"
                      sm="6"
                  >

                    <b-pagination
                        v-model="currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        class="mb-0 mt-1 mt-sm-0 "
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-3">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="editPrograme()"
                  :disabled="(checkedLocation.length ===0 || programme.name ==='' || programme.start_date > programme.end_date)"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="shadow text-primary ml-1"
                  type="button"
                  variant="outline-white"
                  @click="cancel()"
              >
                Cancel
              </b-button>
            </div>


          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {tasks} from './todoTO_BE_REMOVED'
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTable,
  BPagination,
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import ProgrammesAPI from '@/apis/modules/programmes'
import { data } from 'vue-echarts'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTable,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isEditProgrammeSidebarActive',
    event: 'update:is-edit-programme-sidebar-active'
  },
  props: {
    isEditProgrammeSidebarActive: {
      type: Boolean,
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data () {
    return {
      checkedLocation: [],
      currentPage: 1,
      tableLoading: false,
      minDate:'',
      hide_btn: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      selected: [],
      alreadySelected: [],
      selectedCheck: true,
      selectedOption: {
        title: 'All',
        value: 1
      },
      //   options: [
      //         {label: '1',  id: 0, checked: false},
      //         {label: '2',  id: 1, checked: false},
      //         {label: '3',  id: 2, checked: false}
      //       ],
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'address',
          label: 'ADDRESS'
        },
        // {
        //   key: 'service_charge',
        //   label: 'SERVICE CHARGE'
        // },
      ],
      required,
      alphaNum,
      email,
      programme: {
        name: '',
        budget: '',
        start_date: '',
        end_date: '',
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      tasks: tasks
    }
  },
  methods: {
    async getLocationList(){
      try{
        this.tableLoading = false
        const Response = await ProgrammesAPI.getLocations(this.currentPage, 10)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          service_charge: x.service_charge,
          address: x.address + ', '+ x.city + ', ' + x.postal_code,
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        return dataArray;

      }catch{
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

      this.tableLoading = false
    },
    async editPrograme(){

      if (await this.$refs.UpdatePcnForm.validate()){
        try{
          this.tableLoading = true
          const data = this.checkedLocation.map((x) => (
              x.id
          ))

          let payload = {}
          payload.programme = this.programme;
          payload.location_ids = data

          await ProgrammesAPI.editProgramme(this.$route.params.id, payload)
          this.clearData()
          this.$emit('dataRefresh')
          this.showSuccessMessage('The programme was edited')
          this.$refs.table.refresh()
          this.tableLoading = false

        }catch(error){
          this.convertAndNotifyError(error)
          this.selected = []
          this.selected.length === 0
          this.tableLoading = false
        }
      }

      this.$refs.table.refresh()
      this.tableLoading = false
    },
    cancel(){
      this.$refs.table.refresh()
      this.tableLoading = false
      this.selected = []
      this.selected.length === 0
      this.$emit('dataRefresh')
    },
    clearData(){
      this.programme.name = null;
      this.programme.start_date = null;
      this.programme.end_date = null;
      this.programme.budget = null;

    },
    // dataRefresh(){
    //   this.$refs.table.refresh()
    // },
    toggle () {
      //this.$emit('toggleSideBars')
      this.selected = []
      this.$refs.table.refresh()
      this.isEditProgrammeSidebarActive = false
    },



    async setDetails(){
      const Response  = (await ProgrammesAPI.getProgrammeDetails(this.$route.params.id)).data.data[0]

      this.programme.name = Response.name;
      this.programme.start_date = Response.start_date;
      this.programme.end_date = Response.end_date;
      this.programme.budget = Response.budget;

      this.checkedLocation = Response.programme_location_details.map((x) =>({
        id: x.id,
        name: x.name,
        service_charge: x.service_charge,
        address: x.address + ', '+ x.city + ', ' + x.postal_code,
      }))
    },

  },
  async mounted(){


  },
  watch: {
    async isEditProgrammeSidebarActive (val){
      if(val) {

        this.currentPage = 1
        await this.getLocationList()
        await this.setDetails()
      }else {
        this.$refs.CreatePcnForm.reset();
      }



    },
    'programme.start_date': function(value){
      this.minDate = new Date(value)
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#edit-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
